<template>
  <section>
    <b-row class="match-height">
      <div class="col-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Categories ({{ categories.length }})
            </h4>
          </div>
          <div class="card-datatable table-responsive">
            <table class="datatables-ajax table ">
              <thead>
                <tr>
                  <!-- <th>SN</th> -->
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Services</th>
                  <th>Source</th>
                  <th>Charge</th>
                  <th>Commission</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <!-- <th>SN</th> -->
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Source</th>
                  <th>Type</th>
                  <th>Services</th>
                  <th>Charge</th>
                  <th>Commission</th>
                  <th>Actions</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(category, index) in categories"
                  :key="index"
                >
                  <!-- <td>{{ index + 1 }}</td> -->
                  <td>
                    <b-avatar
                      size="42"
                      :src="category.logo"
                    />
                  </td>
                  <td>
                    {{ category.name }}
                  </td>
                  <td>{{ category.type }}</td>
                  <td>{{ category.services.length }}</td>
                  <td>{{ category.source }}</td>
                  <td>{{ category.charge_formatted }}</td>
                  <td>{{ category.commission_formatted }}</td>
                  <td>
                    <b-link
                      :to="{ name: 'apps-category-edit', params: { id: category.id } }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      Edit
                    </b-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BLink, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BLink,
    BAvatar,
  },
  data() {
    return {
      data: {},
      categories: [],
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    getCategories() {
      this.$http.get(`${this.$url}/services/category/all`)
        .then(response => {
          this.categories = response.data.data
          // console.log(this.categories)
        })
    },
  },
}
</script>
